import React from 'react';
import { useState} from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { launchParameters } from '../services/user.service';

registerLocale('es', es)


function RunParametes() {
  const [startDate, setStartDate] = useState(new Date());
  const [sentParam, setSentParam] = useState('')


  const handleDate = (date,) => {
    setStartDate(date);
  }

  const handleClick = (parmode, nicename)=> {
    launchParameters(startDate, [parmode]).then(response => {
      setSentParam(`Cálculo de párametros para ${nicename} finalizado a las ${response.data.time}. Estado final: ${response.data.state==='failed' ? 'error': 'éxito' }.`)
    })
    .catch(error => {
      console.log(error);
    });
    setSentParam(`Petición de cálculo de parámetros para ${nicename} enviada a las \n ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}.`)
    
  }

  return (

    <div className='grow min-w-full'>
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Calcular Parámetros</h3></div>
      <DatePicker locale="es" portalId="root-portal" dateFormat="dd/MM/yyyy" selected={startDate} onChange={handleDate} popperClassName="date-picker-reports"/>

      <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10 mx-10" title="Calcula parámetros" onClick={() => handleClick('lastprice', 'Precio Último')}>Precio Último</button>
      <div className={`py-2 px-5 text-white mt-2 mx-auto w-2/3 max-w-fit rounded-3xl ${sentParam ? 'bg-cyan-500/20 ' : ''}`}>{sentParam}</div>


    </div>
  );
}

export default RunParametes;