import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";

import { logout, getCurrentUser } from "./services/auth.service";

import Login from "./components/login.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardLast from "./components/board-last.component";
import BoardFast from "./components/board-fast.component";
import BoardAdmin from "./components/board-admin.component";
import EventBus from "./common/EventBus";
import BoardParams from "./components/board-params";
import BoardCompliance from "./components/board-compliance";

const App = () => {
  const [showOpBoard, setShowOpBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  let isparam = useLocation().pathname.includes("parameters");
  let iseditparam = useLocation().pathname.includes("parameters/edit");
  let iscompliance = useLocation().pathname.includes("compliance");

  const logOut = () => {
    logout();
    setShowOpBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  useEffect(() => {
    const user = getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowOpBoard(
        user.roles.some((role) =>
          ["ROLE_OPERATOR", "ROLE_ADMIN"].includes(role)
        )
      );
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <nav className="w-full flex flex-wrap items-center justify-between py-4 bg-teal-100 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light sticky top-0 z-1">
        {/* <div className="container flex flex-wrap items-center justify-between mx-auto">
        <Link to={"/"} className="">
          Arfima
        </Link>
        </div> */}
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          {currentUser && (
            <>
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                {showAdminBoard && (
                  <li>
                    <Link
                      to={"/admin"}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Gestión de Usuarios
                    </Link>
                  </li>
                )}

                {showOpBoard && (
                  <>
                    <li>
                      <Link
                        to={"/admin/parameters/lastprice"}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        {isparam ? "Parámetros Last Price" : "Parámetros"}
                      </Link>
                    </li>
                    {isparam ? (
                      <>
                        <li>
                          <Link
                            to={"/admin/parameters/fastmarket"}
                            className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                          >
                            Parámetros FastMarket
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/admin/parameters/calc"}
                            className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                          >
                            Calcular Parámetros
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/admin/parameters/edit/lastprice"}
                            className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                          >
                            {iseditparam
                              ? "Editar Parámetros Precio Último"
                              : "Editar Parámetros"}
                          </Link>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <Link
                        to={"/last"}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        Last Price
                      </Link>
                    </li>
                  </>
                )}

                <li>
                  <Link
                    to={"/fast"}
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Fast Market
                  </Link>
                </li>
                {showOpBoard ? (
                  <>
                    <li>
                      <Link
                        to={"/compliance/report"}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        {iscompliance
                          ? "Informe de Cumplimiento"
                          : "Cumplimiento"}
                      </Link>
                    </li>
                    {iscompliance ? (
                      <>
                        <li>
                          <Link
                            to={"/compliance/mmconditions"}
                            className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                          >
                            Mantenimiento de Creadores de Mercado
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </>
                ) : null}
              </ul>
            </>
          )}
        </div>

        {currentUser ? (
          <div className="flex md:order-2">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li className="nav-item">
                <a
                  href="/login"
                  className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  onClick={logOut}
                >
                  LogOut
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="flex md:order-2">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li className="nav-item">
                <Link
                  to={"/login"}
                  className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>

      <div className="overflow-x-auto bg-blue-200 h-full" id="main_app">
        <Routes>
          <Route path="/" element={<BoardFast />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/last" element={<BoardLast isad={showOpBoard} />} />
          <Route
            path="/compliance/:comppage"
            element={<BoardCompliance isad={showOpBoard} />}
          />
          <Route path="/fast" element={<BoardFast />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/admin/parameters/:parpage/" element={<BoardParams />} />
          <Route
            path="/admin/parameters/:parpage/:partoedit"
            element={<BoardParams />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
