import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {getOpBase} from "../services/user.service";
import EventBus from "../common/EventBus";
import ComplianceReport from "./compliancereport.component";
import MMConditions from "./mmconditions.component";

const BoardCompliance = () => {
  const [content, setContent] = useState({});
  let { comppage } = useParams();

  useEffect(() => {
    getOpBase().then(response => {
      setContent(response.data);
    }, error => {
      setContent((error.response && error.response.data && error.response.data.message) || error.message || error.toString());

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    });
  }, []);
  
  return <div className="mt-10 mx-auto container text-center" name="bcm">
              {content.comp === "瓦斯" ? (comppage === 'report'? <ComplianceReport/> :  (comppage === 'mmconditions'? <MMConditions/> :  null)) : null}
      </div>;
};

export default BoardCompliance;