import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `https://${window.location.host}/okapi/mibui/`;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "lp", { headers: authHeader() });
};

const getFastMarketBoard = () => {
  return axios.get(API_URL + "fm", { headers: authHeader() });
};

const getOpBase = () => {
  return axios.get(API_URL + "mb", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const getFastMarketData = (date) => {
  return axios.get(API_URL + `fm/${date.toISOString()}`, {
    headers: authHeader(),
  });
};

const getPlotData = (date, products) => {
  let plotDataRequest = JSON.stringify({
    date: date.toISOString(),
    products: products,
  });
  return axios.get(API_URL + `fmplot/${plotDataRequest}`, {
    headers: authHeader(),
  });
};

const getData = (endpoint) => {
  return axios.get(API_URL + endpoint, { headers: authHeader() });
};

const getComplianceData = (mm, month) => {
  return axios.get(API_URL + `cmpl/${mm}/${month}`, { headers: authHeader() });
};

const setComplianceData = (rows) => {
  return axios.post(API_URL + "cmpl", { rows }, { headers: authHeader() });
};

const runMMCompliance = (market_maker, month) => {
  console.log(market_maker, month)
  let paramRequest = { market_maker:market_maker, datestr:month };
  return axios.post(
    API_URL + "cmpl/runCompl",
    { paramRequest },
    { headers: authHeader() }
  );
};

const runMMComplReport = (market_maker, month) => {
  console.log(market_maker, month)
  let paramRequest = { market_maker:market_maker, datestr:month };
  return axios.post(
    API_URL + "cmpl/runComplRep",
    { paramRequest },
    { headers: authHeader() }
  );
};

const runMMComplSpreads = (market_maker, month) => {
  console.log(market_maker, month)
  let paramRequest = { market_maker:market_maker, datestr:month };
  return axios.post(
    API_URL + "cmpl/runComplSpr",
    { paramRequest },
    { headers: authHeader() }
  );
};

const getConditionsData = (month) => {
  return axios.get(API_URL + `mmcond/${month}`, { headers: authHeader() });
};

const setConditionsData = (changedData) => {
  return axios.post(
    API_URL + `mmcond`,
    { changedData },
    { headers: authHeader() }
  );
};

const getPrevConditionsData = (month) => {
  return axios.get(API_URL + `mmprevcond/${month}`, { headers: authHeader() });
};

const getParametersData = (service) => {
  return axios.get(API_URL + `par/${service}`, { headers: authHeader() });
};

const getFMParametersData = () => {
  return axios.get(API_URL + `fmpar`, { headers: authHeader() });
};

const setFMParametersData = (rowsobject) => {
  return axios.post(
    API_URL + "fmpar",
    { rowsobject },
    { headers: authHeader() }
  );
};

const getDefaultParametersData = (service) => {
  return axios.get(API_URL + `defpar/${service}`, { headers: authHeader() });
};

const setDefaultParametersData = (rows) => {
  return axios.post(API_URL + "defpar", { rows }, { headers: authHeader() });
};

const setParametersData = (rows) => {
  return axios.post(API_URL + "par", { rows }, { headers: authHeader() });
};

const launchParameters = (date, modes) => {
  let paramRequest = {
    datestr: date.toISOString().split("T")[0],
    calculateparams4: modes,
  };
  return axios.post(
    API_URL + "par/calc",
    { paramRequest },
    { headers: authHeader() }
  );
};

const sendMibgas = (date) => {
  let paramRequest = { datestr: date.toISOString().split("T")[0] };
  return axios.post(
    API_URL + "stt/mib",
    { paramRequest },
    { headers: authHeader() }
  );
};

const reRunProcess = (date) => {
  let paramRequest = { datestr: date.toISOString().split("T")[0] };
  return axios.post(
    API_URL + "stt/rerun",
    { paramRequest },
    { headers: authHeader() }
  );
};

const reRunExcel = (date) => {
  let paramRequest = { datestr: date.toISOString().split("T")[0] };
  return axios.post(
    API_URL + "stt/rerunExcel",
    { paramRequest },
    { headers: authHeader() }
  );
};

const getSettleData = (date) => {
  return axios.get(API_URL + `stt/${date.toISOString().split("T")[0]}`, {
    headers: authHeader(),
  });
};

const setSettleData = (rows) => {
  return axios.patch(API_URL + "stt", { rows }, { headers: authHeader() });
};

const updateUserRole = (userId, role) => {
  return axios.post(
    API_URL + "updateUserRole",
    { userId: userId, roleName: role },
    { headers: authHeader() }
  );
};

export const deleteUser = (userId) => {
  return axios.delete(API_URL + `users/${userId}`, { headers: authHeader() });
};

export {
  getPublicContent,
  getAdminBoard,
  getOpBase,
  getUserBoard,
  getFastMarketBoard,
  getFastMarketData,
  getComplianceData,
  setComplianceData,
  runMMCompliance,
  runMMComplReport,
  runMMComplSpreads,
  getConditionsData,
  setConditionsData,
  getPrevConditionsData,
  getParametersData,
  setParametersData,
  getFMParametersData,
  setFMParametersData,
  getDefaultParametersData,
  setDefaultParametersData,
  launchParameters,
  sendMibgas,
  getPlotData,
  getSettleData,
  setSettleData,
  reRunProcess,
  reRunExcel,
  getData,
  updateUserRole,
};
